import history from "../tools/Routes/CustomHistory";
import {ViewGridIcon, CogIcon, LogoutIcon} from "@heroicons/react/outline";
import {useEffect, useState} from "react";
import HeaderListMenu from "./HeaderListMenu";
import axios from "axios";
import {IInfos} from "../interfaces";
import Loading from "./Loading";
import TokenService from "../services/token.service";
import HeaderButton from "./HeaderButton";
import ErrorBlock from "./ErrorBlock";

interface IProps {
  onClick: Function;
}

export default function Header({onClick}: IProps) {
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [infos, setInfos] = useState<IInfos>({
    name: "",
    email: "",
  });

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    setLoading(true);
    axios
      .get(`/user/details`)
      .then((res: any) => res.data)
      .then((results: any) => {
        setInfos(results);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setFetchError(true);
          setLoading(false);
          return;
        }
        console.log("Request failed", error);
      });
  };
  return (
    <div
      className="select-none"
      onClick={() => {
        onClick();
      }}>
      <aside className="bg-base-100 bg-opacity-80 backdrop-blur w-72 min-h-screen flex flex-col border-r-2 border-r-neutral">
        <div className="flex-grow">
          {fetchError ? (
            <div className="py-6 flex m-0 px-3">
              <div className="px-4 w-full">
                <ErrorBlock text="Erreur de connexion" />
              </div>
            </div>
          ) : (
            <div
              className="cursor-pointer px-4"
              onClick={() => {
                history.push(`/profile`);
              }}>
              {loading ? (
                <Loading text="Chargement des informations" header={true}></Loading>
              ) : (
                <div className="pt-6 flex m-0 px-3">
                  {infos.image_url ? (
                    <img
                      className={`rounded-full h-16 w-16`}
                      src={infos.image_url}
                      title={infos.name}></img>
                  ) : (
                    <div
                      className={`rounded-full h-16 w-16 flex-col font-medium bg-accent flex items-center justify-center text-base text-accent-content`}
                      title={infos.name}>
                      {infos.name.charAt(0)}
                    </div>
                  )}
                  <div className="ml-3 flex flex-col">
                    <div className="my-auto">
                      <h1 className="text-xl font-semibold">{infos.name}</h1>
                      <h2 className="text-xs font-medium">{infos.email}</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <ul className="menu menu-compact flex flex-col p-0 mt-8 space-y-1">
            <li className="menu-title px-4">
              <span className="!font-normal !text-base !text-base-content !text-opacity-80">Mes pages</span>
            </li>
            <li>
              <HeaderButton
                title="Dashboard"
                linkTo="/dashboard"
                Icon={<ViewGridIcon className="my-auto stroke-[1.3]" />}
              />
            </li>
            <li>
              <HeaderButton
                title="Parametres"
                linkTo="/settings"
                Icon={<CogIcon className="my-auto stroke-[1.3]" />}
              />
            </li>
          </ul>
          <HeaderListMenu />
        </div>
        <div
          className="mb-0 p-4 cursor-pointer"
          onClick={() => {
            TokenService.removeUser();
            history.push("/");
          }}>
          <button className="btn btn-outline border-none normal-case btn-block">
            <div className="w-full flex gap-4">
              <LogoutIcon className="stroke-[1.3] h-9" />
              <span className="my-auto font-medium text-base">Deconnexion</span>
            </div>
          </button>
        </div>
      </aside>
    </div>
  );
}
