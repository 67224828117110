import {useContext, useEffect, useReducer, useState} from "react";
import ErrorBlock from "./ErrorBlock";
import history from "../tools/Routes/CustomHistory";
import formReducerService from "../services/formReducer.service";
import axios from "axios";
import {Link, useOutletContext} from "react-router-dom";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import {TitleContext} from "./Context/TitleContextProvider";

export default function SignUpCard() {
  const setDisplayModal: Function = useOutletContext();
  const formReducer = formReducerService.formReducer;

  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Données invalides");

  const [formData, setFormData] = useReducer(formReducer, {
    email: "",
    password: "",
  });

  const PAGE_TITLE = "Inscription";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  // Computes a modified password to meet with server's password complexity expectations
  const computePassword = (password: string): string => {
    //TODO Add special chars
    const nbLower = (password.match(/[a-z]/g) || []).length;
    const nbUpper = (password.match(/[A-Z]/g) || []).length;
    const nbNumbers = password.replace(/[^0-9]/g, "").length;
    if (
      !((password.length - 8) * (password.length - 100) <= 0) ||
      nbNumbers < 1 ||
      nbUpper < 1 ||
      nbLower < 1
    ) {
      return "";
    }
    return password;
  };

  const [modifiedPassword, setModifiedPassword] = useState<string>(formData?.password);
  useEffect(() => {
    // Updates PasswordStrengthBar' password value with computed one
    // to meet with server's password complexity expectations
    const computedPassword = computePassword(formData?.password);
    setModifiedPassword(computedPassword);
  }, [formData]);

  const signUpUser = async (credentials: any) => {
    setFormError(false);
    setLoading(true);
    axios
      .post(`/auth/register`, {}, {params: credentials.formData})
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        try {
          setLoading(false);
          setSubmitted(true);
          setTimeout(() => {
            setFormError(false);
            setDisplayModal(true);
          }, 1500);
        } catch (err) {
          throw err;
        }
      })
      .catch(function (error) {
        setFormError(true);
        Array.from(document.querySelectorAll("input")).forEach(
          (input) => (input.value = "")
        );
        setLoading(false);

        if (error.response.status === 404 || 403) {
          try {
            const errList: any[] | undefined = error.response?.data?.message;
            if (errList) {
              if (errList.includes("Password too weak")) {
                setErrorMessage("Mot de passe Invalide");
              }
              if (errList.includes("Email is required")) {
                setErrorMessage("Format d'email invalide");
              }
              if (errList.includes("Email already in use")) {
                setErrorMessage("Email déjà lié à un compte");
              }
            }
          } catch (err) {
            throw err;
          }
          return;
        }
        if (error.response.status === 500) {
          setErrorMessage("Erreur du serveur veuillez réesayer plus tard");
        }
        console.log("Request failed", error);
        history.push("/404");
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setFormError(true);
    } else {
      signUpUser({formData});
    }
  };

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <div className="card w-96">
      <div className="card-body">
        <form onSubmit={handleSubmit} className="space-y-4">
          <h1 className="font-medium text-4xl">Inscription</h1>
          <p>Creez votre compte et profitez de toutes les fonctionnalités de RajApp</p>
          {formError && <ErrorBlock text={errorMessage}></ErrorBlock>}
          <div className="form-control">
            <label className="label">
              <span className="label-text font-semibold">Nom</span>
            </label>
            <input
              name="name"
              type="text"
              placeholder="nom"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit focus:outline-none
                         placeholder-shown:!border-none placeholder-shown:!bg-base-200 placeholder-shown:!caret-base-content
                         invalid:border-error
                         focus:ring-1
                         focus:placeholder-shown:!outline-none
                         focus:valid:ring-success
                         focus:invalid:ring-error
                         focus:invalid:placeholder-shown:!ring-0"
              required
              disabled={loading}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-semibold">Email</span>
            </label>
            <input
              name="email"
              type="email"
              placeholder="email"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit focus:outline-none
                         placeholder-shown:!border-none placeholder-shown:!bg-base-200 placeholder-shown:!caret-base-content
                         invalid:border-error
                         focus:ring-1
                         focus:placeholder-shown:!outline-none
                         focus:valid:ring-success
                         focus:invalid:ring-error
                         focus:invalid:placeholder-shown:!ring-0"
              required
              disabled={loading}
            />
          </div>
          <div className="form-control">
            <div className="flex">
              <label className="label flex-none gap-2">
                <span className="label-text font-semibold">Mot de passe</span>
              </label>
              <div className="dropdown dropdown-top flex-1 my-auto">
                <label tabIndex={0} className="btn btn-circle btn-ghost btn-xs text-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 stroke-current">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </label>
                <div
                  tabIndex={0}
                  className="card compact bg-base-100 text-base-content dropdown-content shadow-sm w-max -ml-[5rem] border border-base-300">
                  <div className="card-body flex">
                    <h1 className="font-bold text-base">Doit contenir au moins:</h1>
                    <ul className="list-disc flex flex-col px-6 marker:text-xs marker:leading-3">
                      <li>8 caractères</li>
                      <li>1 minuscule</li>
                      <li>1 majuscule</li>
                      <li>1 chiffre</li>
                      <li>
                        1 caractère spécial (
                        <span className="text-primary tracking-widest">!@#$&*?/</span>)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <input
              name="password"
              type="password"
              placeholder="mot de passe"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit focus:outline-none
                         placeholder-shown:!border-none placeholder-shown:!bg-base-200 placeholder-shown:!caret-base-content
                         invalid:border-error
                         focus:ring-1
                         focus:placeholder-shown:!outline-none
                         focus:valid:ring-success
                         focus:invalid:ring-error
                         focus:invalid:placeholder-shown:!ring-0"
              required
              pattern="((?=.*[A-Z])(?=.*[!@#$&*?/])(?=.*[0-9])(?=.*[a-z]).{8,})"
              disabled={loading}
            />
          </div>
          <div className="card-actions pt-6">
            <button
              className={`btn btn-primary btn-block normal-case ${loading && "loading"} ${
                submitted && "btn-success"
              }`}
              type="submit">
              {submitted && (
                <UseAnimations
                  animation={radioButton}
                  className={`stroke-neutral-content !h-[1.4rem] mr-2 ${
                    submitted && "stroke-success-content"
                  }`}
                  strokeColor="var(--n)"
                  speed={0.7}
                  size={16}
                  autoplay
                  wrapperStyle={{height: "auto", width: "auto"}}
                />
              )}
              {submitted ? "Confirmation" : "Inscription"}
            </button>
          </div>
        </form>
      </div>
      <div className="label-text font-semibold-alt mx-auto">
        <span>Déjà inscrit ? </span>
        <span className=" link md:link-hover">
          <Link to="/auth/login">Connectez vous</Link>
        </span>
      </div>
    </div>
  );
}
