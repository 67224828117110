import {
  PencilIcon,
  TicketIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import {FC, useState, useContext, useReducer, useEffect} from "react";
import {ListContext} from "./Context/ListContextProvider";
import formReducerService from "../services/formReducer.service";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import axios from "axios";
import {useParams} from "react-router-dom";

export const EditListMembers: FC = (): JSX.Element => {
  let {LIST_ID} = useParams();
  const {listDetails, fetchDetails} = useContext(ListContext);

  const [displayModal, setDisplayModal] = useState(false);
  const [changing, setChanging] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formReducer = formReducerService.formReducer;
  const [formData, setFormData] = useReducer(formReducer, {
    list_title: listDetails?.name || "",
  });
  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  // const updateListDetails = async () => {
  //   setChanging(true);

  //   await axios({
  //     method: "post",
  //     url: `/list/details`,
  //     data: bodyFormData,
  //     params: {list_id: LIST_ID, name: formData.list_title},
  //     headers: {"Content-Type": "multipart/form-data"},
  //   })
  //     .then((res: any) => {
  //       return res.data;
  //     })
  //     .then(async (result: any) => {
  //       try {
  //         setSubmitted(true);
  //         fetchDetails();
  //         setTimeout(() => {
  //           setSubmitted(false);
  //           setDisplayModal(false);
  //         }, 1200);
  //       } catch (err) {
  //         throw err;
  //       }
  //       return;
  //     })
  //     .catch(function (error: any) {});
  //   setChanging(false);
  // };

  useEffect(() => {
    // Reset on modal opening
    // setFormData({name: "list_title", value: listDetails?.name});
  }, [displayModal]);

  return (
    <div className="">
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={displayModal}
        readOnly
      />
      <div className="modal modal-bottom lg:modal-middle">
        <div className="modal-box flex flex-col space-y-4">
          <div className={``}>
            <div className="form-control">
              {/* <label className="label">
                <span className="label-text font-semibold">Label</span>
              </label>
              <div className="flex flex-col">
                <input
                  placeholder="Nom"
                  name="list_title"
                  type="text"
                  value={formData.list_title}
                  className="font-bold text-3xl input-lg input flex w-auto !bg-base-200"
                  onChange={handleChange}></input>
              </div> */}
            </div>
            <div className="pt-8 flex place-content-end gap-4">
              <button
                className={`btn btn-lg grow lg:grow-0 lg:btn-md px-7 btn-ghost normal-case ${
                  (changing || submitted) && "hidden"
                }`}
                onClick={() => {
                  setDisplayModal && setDisplayModal(false);
                }}>
                Fermer
              </button>
              {/* <button
                className={`btn grow lg:grow-0 btn-lg lg:btn-md btn-primary normal-case ease-in-out duration-700 transition-all animate-none !ml-0 ${
                  changing && "loading"
                } ${submitted && "btn-success !grow"}
                    active:hover:transform-none active:hover:animate-none
                    active:focus:transform-none active:focus:animate-none`}
                onClick={() => {
                  // updateListDetails();
                }}>
                {submitted && (
                  <UseAnimations
                    animation={radioButton}
                    className={`stroke-neutral-content !h-[1.4rem] mr-1 ${
                      submitted && "stroke-success-content"
                    }`}
                    strokeColor="var(--n)"
                    speed={0.7}
                    size={16}
                    autoplay
                    wrapperStyle={{height: "auto", width: "auto"}}
                  />
                )}
                {submitted ? "Terminé" : changing ? "Changement en cours" : "Changer"}
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-circle border-none bg-base-100 text-base-content hover:text-primary-content normal-case gap-2"
        onClick={() => setDisplayModal(true)}>
        <UserGroupIcon className="h-6 w-6 stroke-[1.8]" />
      </button>
    </div>
  );
};
