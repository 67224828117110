import { defaultDarkTheme, defaultLightTheme }  from "../config";


const getLocalTheme = () => {
  const theme =
    localStorage.getItem("theme") ||
    (window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      defaultDarkTheme) ||
    defaultLightTheme;
  return theme;
};

const setLocalTheme = (theme: string) => {
  localStorage.setItem("theme", theme);
};

const ThemeService = {
  getLocalTheme,
  setLocalTheme,
};

export default ThemeService;
