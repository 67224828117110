import axios from "axios";
import {createContext, FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ITask} from "../../interfaces";

interface IListDetailsUser {
  name: string;
  image_url: string | null;
}

interface IListDetails {
  name: string;
  image_url: string | null;
  cover_image_url: string | null;
  owner_name: string;
  users: IListDetailsUser[];
  is_admin: boolean;
}

interface IListContextData {
  tasks?: Partial<ITask[]>;
  listDetails?: IListDetails;
  updateSubtask: (id: number, new_state: boolean) => Promise<void>;
  updateTask: (id: number, new_state: boolean) => Promise<void>;
  fetchDetails: () => Promise<void>;
  loading: boolean;
  loadingButtons: boolean;
  error: boolean;
  isEdit: boolean;
  setIsEdit: (new_state: boolean) => void;
}

export const ListContext = createContext({} as IListContextData);

export const ListContextProvider: FC = ({children}) => {
  let {LIST_ID} = useParams();
  const [tasks, setTasks] = useState<Partial<ITask[]>>();
  const [listDetails, setListDetails] = useState<IListDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButtons, setLoadingButtons] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);

  const fetchTasks = async () => {
    setLoadingButtons(true);
    await axios
      .get(`/list/task`, {params: {list_id: LIST_ID}})
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        setError(false);
        setTasks(result);
      })
      .catch(function (error: any) {
        // setError(true);
        if (error.response.status === 404) {
          const errList: any[] | undefined = error.response?.data?.message;
          if (errList) {
            if (errList.includes("Tasks not found")) {
              setTasks([]);
            }
          }
        }
      });
    setLoadingButtons(false);
  };

  const fetchDetails = async () => {
    await axios
      .get(`/list/details`, {params: {list_id: LIST_ID}})
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        setError(false);
        setListDetails(result);
      })
      .catch(function (error: any) {
        setError(true);
      });
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchDetails(), fetchTasks()]);
    setLoading(false);
  };

  const updateSubtask = async (id: number, new_state: boolean) => {
    await axios
      .put(
        `/list/subtask`,
        {},
        {
          params: {id: id, new_state: new_state},
        }
      )
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        fetchTasks();
        //TODO update subpart of object
        return;
      })
      .catch(function (error: any) {
        // setError(true);
      });
  };

  const updateTask = async (id: number, new_state: boolean) => {
    await axios
      .put(
        `/list/task`,
        {},
        {
          params: {id: id, new_state: new_state},
        }
      )
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        fetchTasks();
        //TODO update subpart of object
        return;
      })
      .catch(function (error: any) {
        // setError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [LIST_ID]);

  return (
    <ListContext.Provider
      value={{
        tasks: tasks,
        listDetails,
        loading,
        loadingButtons,
        error,
        updateSubtask: updateSubtask,
        updateTask: updateTask,
        fetchDetails: fetchDetails,
        isEdit: isEdit,
        setIsEdit: setIsEdit,
      }}>
      {children}
    </ListContext.Provider>
  );
};
