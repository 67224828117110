import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

interface IProps {
  text: string;
}

export default function ErrorBlock({ text }: IProps) {
  return (
    <div className="alert alert-error">
      <div>
        <ErrorOutlineOutlinedIcon className="flex-shrink-0 h-6 w-6" />
        <span>{text}</span>
      </div>
    </div>
  );
}
