import {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import {TitleContext} from "../../components/Context/TitleContextProvider";

function Home() {
  const PAGE_TITLE = "Accueil";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  return (
    <div>
      <h1>Hi there</h1>
      <nav>
        <ul>
          <li>
            <Link to="/app">App</Link>
          </li>
          <li>
            <Link to="/auth/login">Se connecter</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/test">TEST</Link>
          </li>
          <li>
            <Link to="/list/1">OLD RajApp</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Home;
