import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {TitleContextProvider} from "./components/Context/TitleContextProvider";
import {ThemeContextProvider} from "./components/Context/ThemeContextProvider";

export {};

declare global {
  interface Window {
    _env_: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <TitleContextProvider>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </TitleContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
