import axios from "axios";
import {useEffect, useState} from "react";
import {IDashboardItem} from "../interfaces";
import ErrorBlock from "./ErrorBlock";
import HeaderButton from "./HeaderButton";
import Loading from "./Loading";

export default function HeaderListMenu() {
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [lists, setLists] = useState<IDashboardItem[]>([]);

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = () => {
    setLoading(true);
    axios
      .get(`/list`)
      .then((res: any) => res.data)
      .then((results: any) => {
        setLists(results);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setFetchError(true);
          setLoading(false);
          return;
        }
        console.log("Request failed", error);
      });
  };

  const renderLists = () => {
    return lists.slice().map((list, index) => {
      return (
        <li className="cursor-pointer" key={index}>
          <HeaderButton
            title={list.name}
            linkTo={`/list/${list.list_id}`}
            image_url={list.image_url}
          />
        </li>
      );
    });
  };

  return (
    <ul className="menu menu-compact flex flex-col p-0 mt-8 space-y-1">
      <li className="menu-title px-4">
        <span className="!font-normal !text-base !text-base-content !text-opacity-80">Mes listes</span>
      </li>
      {fetchError ? (
        <div className="py-6 flex m-0 px-3">
          <ErrorBlock text="Erreur de connexion" />
        </div>
      ) : loading ? (
        <Loading text="Chargement des listes" header={true}></Loading>
      ) : (
        renderLists()
      )}
    </ul>
  );
}
