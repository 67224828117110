import { ExclamationIcon } from "@heroicons/react/outline";

interface IProps {
  text: string;
}

export default function WarningBlock({ text }: IProps) {
  return (
    <div className="alert alert-warning flex-row">
      <div className="grow">
        <span className="h-6 w-6 flex-none">
          <ExclamationIcon className="my-auto stroke-[1.7]" />
        </span>
        <span className="text-center font-medium flex-1">{text}</span>
      </div>
    </div>
  );
}
