import { fontGrid } from "@mui/material/styles/cssUtils";
import { render } from "@testing-library/react";
import TimeAgo from "javascript-time-ago";
import fr from "javascript-time-ago/locale/fr.json";

TimeAgo.addDefaultLocale(fr);
const timeAgo = new TimeAgo("fr-FR");

interface IProps {
  time: string;
  dailyTimeout?: boolean;
  short?: boolean;
  dayOnly?: boolean;
}

const renderSwitchDayOnly = (time: string) => {
  switch (
    Math.ceil(
      (new Date(time).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    )
  ) {
    case -2:
      return "Avant-hier";
    case -1:
      return "Hier";
    case 0:
      return "Aujourd'hui";
    case 1:
      return "Demain";
    case 2:
      return "Après-demain";
    default:
      return timeAgo.format(
        Date.parse(new Date(new Date(time).getTime()).toDateString()),
        "twitter-now"
      );
  }
};

export default function TimerFrom({
  time,
  dailyTimeout = false,
  short,
  dayOnly,
}: IProps) {
  return (
    <div className="timerFromContainer">
      {new Date(time).getUTCFullYear() === 1 ||
      (dailyTimeout && new Date(time).getDate() != new Date().getDate()) ? (
        !short ? (
          <div>
            <span className="font-light">Aucun changement </span>
            <span className="font-semibold">aujourd'hui</span>
          </div>
        ) : (
          <div>
            <span className="font-normal">Jamais</span>
          </div>
        )
      ) : (
        <div>
          <span className="font-light">{!short && "Dernier changement "}</span>
          <span className={`${!short ? "font-semibold" : "font-normal"}`}>
            {dayOnly
              ? renderSwitchDayOnly(time)
              : timeAgo.format(Date.parse(time))}
          </span>
        </div>
      )}
    </div>
  );
}
