import {Comment} from "../../components/Comment";
import {LastDone} from "../../components/LastDone";
import NoRights from "../ErrorPages/NoRights";
import Loading from "../../components/Loading";
import {useContext, useEffect} from "react";
import {ListContext} from "../../components/Context/ListContextProvider";
import WarningBlock from "../../components/WarningBlock";
import Task from "../../components/Task";
import {ImageCircle} from "../../components/ImageCircle";
import {cdn_url} from "../../config";
import {CheckIcon, PlusSmIcon} from "@heroicons/react/outline";
import {TitleContext} from "../../components/Context/TitleContextProvider";
import {CircularProgressbarWithChildren, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {ThemeContext} from "../../components/Context/ThemeContextProvider";
import {EditListDetails} from "../../components/EditListDetails";
import {EditListMembers} from "../../components/EditListMembers";

export default function List() {
  const {tasks, listDetails, loading, error, isEdit, setIsEdit} = useContext(ListContext);
  const {theme: theme} = useContext(ThemeContext);

  const USER_IMG_LIM: number = 3;

  const PAGE_TITLE = listDetails?.name || "Liste sans nom";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  const renderTasks = () => {
    return (
      tasks &&
      tasks.slice().map((task, index) => {
        return (
          task && (
            <Task
              key={index}
              name={task.name}
              task_id={task.task_id}
              time={task.time}
              User={task?.User || null}
              state={task?.state}
              Subtask={task?.Subtask}
            />
          )
        );
      })
    );
  };

  const totalPortionOfChecked: number =
    (tasks
      ? tasks.reduce(
          (a, b) =>
            a +
            (b
              ? b.Subtask
                ? b.Subtask.reduce((c, d) => c + (d.state ? 1 : 0), 0)
                : b.state
                ? 1
                : 0
              : 0),
          0
        )
      : 0) /
    (tasks
      ? tasks.reduce(
          (a, b) => a + (b ? (b.Subtask ? b.Subtask.length : 1 ? 1 : 0) : 0),
          0
        )
      : 0);

  const renderUserImages = () => {
    return (
      listDetails?.users && (
        <div className="flex flex-row place-content-between">
          <div className="py-5 flex flex-row -p-2 -space-x-4">
            {listDetails.users.slice(0, USER_IMG_LIM).map((user, index) => {
              return (
                <ImageCircle
                  key={index}
                  image_url={user.image_url}
                  name={user.name}
                  custom_className={`border-base-200`}
                />
              );
            })}
            {listDetails.users.length - USER_IMG_LIM > 0 && (
              <div className="pl-1">
                <ImageCircle
                  custom_text={`+${listDetails.users.length - USER_IMG_LIM}`}
                  name={listDetails.users
                    .slice(USER_IMG_LIM)
                    .reduce((a, b) => a + "・" + b.name + "\n", "")}
                  custom_className={`!border-none !bg-transparent !text-base-content  !text-lg`}
                />
              </div>
            )}
          </div>
          {listDetails && listDetails.is_admin && (
            <div className="my-auto flex">
              <EditListMembers />
            </div>
          )}
        </div>
      )
    );
  };

  //TODO LOOK AT USECALLBACK

  if (error) {
    return (
      <NoRights message="Veuillez demander à un administrateur de la liste de vous donner accès"></NoRights>
    );
  }

  return (
    <div
      className="select-none max-w-lg mx-auto w-full flex flex-col
                 lg:flex-row lg:max-w-none lg:mx-0 lg:w-full">
      <div className="flex-none lg:flex-1">
        {loading ? (
          <Loading text="Chargement des données de la liste" />
        ) : (
          <div className="lg:max-w-[35rem] lg:w-full mx-auto">
            <div>
              {listDetails?.cover_image_url ? (
                <div className="max-h-64 h-52 rounded-none sm:rounded-b-xl lg:rounded-none xl:rounded-b-xl -mb-28 -mx-6 2xl:-mx-24 overflow-hidden">
                  <img
                    className="object-cover w-full h-full"
                    src={listDetails.cover_image_url}
                  />
                </div>
              ) : (
                <div
                  className={`h-52 rounded-none sm:rounded-b-xl lg:rounded-none xl:rounded-b-xl -mb-28 -mx-6 2xl:-mx-24 overflow-hidden ${
                    theme === "clear"
                      ? "bg-topo-pattern-clear bg-secondary"
                      : "bg-topo-pattern-dark bg-base-100"
                  }`}></div>
              )}
              <div className="flex flex-row">
                <div className="w-[12rem]">
                  {listDetails && (
                    <div>
                      {listDetails.image_url ? (
                        <img
                          className="rounded-full h-48 w-48 ring-base-200 bg-base-200 ring-8 relative"
                          src={listDetails.image_url}
                          title={listDetails.name}></img>
                      ) : (
                        <div
                          className={`rounded-full h-48 w-48 ring-base-200 ring-8 flex-col font-medium bg-accent flex items-center justify-center text-9xl text-accent-content`}
                          title={listDetails.name}>
                          {listDetails.name.charAt(0)}
                        </div>
                      )}
                      <div className="h-14 w-14 border-8 border-base-200 relative bg-base-200 rounded-full ml-auto mr-0 -mt-14">
                        <CircularProgressbarWithChildren
                          className={``}
                          value={totalPortionOfChecked}
                          maxValue={1}
                          strokeWidth={17}
                          background
                          backgroundPadding={0}
                          styles={buildStyles({
                            pathColor:
                              theme === "clear"
                                ? totalPortionOfChecked === 1
                                  ? "#32D573"
                                  : "#057AFF"
                                : totalPortionOfChecked === 1
                                ? "#15C25A"
                                : "#057AFF",
                            trailColor:
                              theme === "clear"
                                ? totalPortionOfChecked === 1
                                  ? "#32D573"
                                  : "#EAEAEA"
                                : totalPortionOfChecked === 1
                                ? "#15C25A"
                                : "#2B2B2B",
                            strokeLinecap: "round",
                            pathTransitionDuration: 0.5,
                            backgroundColor:
                              theme === "clear"
                                ? totalPortionOfChecked === 1
                                  ? "#32D573"
                                  : "#F7F7F7"
                                : totalPortionOfChecked === 1
                                ? "#15C25A"
                                : "#050505",
                          })}>
                          <CheckIcon
                            className={`p-1 stroke-[3.5] text-primary-content ${
                              totalPortionOfChecked !== 1 && "hidden"
                            }`}
                          />
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row place-content-between gap-4">
                <h1 className="text-6xl pt-5 pb-2 font-extrabold overflow-x-auto mr-auto">
                  {listDetails?.name || "Aucun nom"}
                </h1>
                {listDetails?.is_admin && (
                  <div className="mb-2 mt-auto flex">
                    <EditListDetails />
                  </div>
                )}
              </div>
              {listDetails && renderUserImages()}
            </div>
            <div className="flex flex-row my-6">
              <img
                className="w-14 h-14 my-auto flex"
                src={`${cdn_url}public/icons/tick-front-color.png`}
                alt="tick"></img>
              <h2 className="pl-2 font-bold text-3xl my-auto">
                Liste pour {listDetails?.name || "?"}
              </h2>
            </div>
            {tasks?.length !== 0 ? (
              <div className="space-y-5 mb-10">{renderTasks()}</div>
            ) : (
              <WarningBlock text="Aucune tache trouvée" />
            )}
          </div>
        )}
      </div>
      <div
        className="divider lg:divider-horizontal
                   after:h-[2px] before:h-[2px] before:bg-neutral
                   lg:after:w-[2px] lg:before:w-[2px] after:bg-neutral"></div>
      <div className="flex-grow lg:flex-initial lg:w-96 ">
        <div className="mx-auto space-y-7 mb-10">
          <div className="flex flex-row my-6">
            <img
              className="w-12"
              src={`${cdn_url}public/icons/chat-text-front-color.png`}
              alt="tick"></img>
            <h2 className="pl-2 font-bold text-3xl my-auto">Commentaire</h2>
          </div>
          <Comment />
          <div className="flex flex-row my-6">
            <img
              className="w-12"
              src={`${cdn_url}public/icons/calender-front-color.png`}
              alt="tick"></img>
            <h2 className="pl-2 font-bold text-3xl my-auto">Dernière fois</h2>
          </div>
          <LastDone />
        </div>
      </div>
    </div>
  );
}
