import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";
import TokenService from "../../services/token.service";

interface ProtectedRouteProps extends PropsWithChildren<any>{
  redirectPath?: string;
}

export default function ProtectedRoute({
  redirectPath = '/auth/login',
  children
}: ProtectedRouteProps) {
  
  if (!TokenService.getLocalAccessToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}
