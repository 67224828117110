import {useContext, useState} from "react";
import {ITask} from "../interfaces";
import {SubtaskFC} from "./Subtask";
import {ChevronDownIcon, ClockIcon} from "@heroicons/react/outline";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {ListContext} from "./Context/ListContextProvider";
import {ThemeContext} from "../components/Context/ThemeContextProvider";

export default function Task({task_id, name, time, User, state, Subtask}: ITask) {
  const [changing, setChanging] = useState(false);
  const [deployed, setDeployed] = useState(false);
  const {updateTask} = useContext(ListContext);
  const {theme: theme} = useContext(ThemeContext);

  const handleChange = async (new_state: boolean) => {
    // setChanging(true);
    await updateTask(task_id, new_state);
    // setChanging(false);
  };

  const renderSubtasks = () => {
    return (
      Subtask &&
      Subtask.slice().map((item, index) => {
        return (
          <SubtaskFC
            key={item.subtask_id}
            subtask_id={item.subtask_id}
            name={item.name}
            place={item.place}
            state={item.state}
            User={item.User}
          />
        );
      })
    );
  };

  return (
    <div className="card rounded-xl card-compact px-7 py-3 bg-base-100">
      <div className="card-body !p-0 space-y-3">
        <div className="flex flex-col ">
          <div
            className="flex flex-row hover:cursor-pointer"
            onClick={() =>
              Subtask && Subtask.length !== 0
                ? setDeployed(!deployed)
                : handleChange(!state)
            }>
            <div className="w-[50px] my-auto flex">
              {!Subtask || Subtask.length === 0 ? (
                changing ? (
                  <button className="btn btn-square btn-sm p-0 loading bg-transparent border-0 w-6 h-6 !flex-none mt-2.5 ml-3" />
                ) : (
                  <input
                    id={`input-${task_id.toString()}`}
                    className={`checkbox h-7 w-7 rounded-[4px] checkbox-primary border-2 border-base-300 ${
                      state && "opacity-100"
                    }`}
                    type="checkbox"
                    checked={state}
                    onChange={(e) => handleChange(e.currentTarget.checked)}
                  />
                )
              ) : Subtask.reduce((a, b): number => a + (b.state ? 1 : 0), 0) ===
                Subtask.length ? (
                // Fully checked mark
                <input
                  className={`checkbox h-7 w-7 hover:cursor-default rounded-full checkbox-primary border-2 border-base-300 ${
                    state && "opacity-100"
                  }`}
                  type="checkbox"
                  checked={true}
                  readOnly={true}
                />
              ) : Subtask.reduce((a, b): number => a + (b.state ? 1 : 0), 0) !== 0 ? (
                // Partially checked mark
                <div className="h-7 w-7">
                  <CircularProgressbar
                    value={
                      Subtask.reduce((a, b): number => a + (b.state ? 1 : 0), 0) /
                      Subtask.length
                    }
                    maxValue={1}
                    strokeWidth={13}
                    styles={buildStyles({
                      pathColor: theme === "clear" ? "#057AFF" : "#057AFF",
                      trailColor: theme === "clear" ? "#EAEAEA" : "#2B2B2B",
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.5,
                    })}
                  />
                </div> //Empty checked mark
              ) : (
                <div className="h-7 w-7 rounded-[100px] bg-dashed-circle" />
              )}
            </div>
            <div className="card-title left-0 flex flex-col ">
              <h2 className="flex my-auto font-semibold text-xl mx-auto ml-0">{name}</h2>
              <div className="flex flex-row ml-0 mx-auto pl-1 pr-2 space-x-1 rounded-full bg-secondary">
                <div className="h-4 w-4 flex my-auto">
                  <ClockIcon className="text-secondary-content my-auto stroke-[2]" />
                </div>
                <div className="text-secondary-content py-[1px] text-sm mx-auto ml-0 font-normal">
                  {`${new Date(time).getUTCHours()}h${
                    new Date(time).getUTCMinutes() !== 0
                      ? new Date(time).getUTCMinutes()
                      : ""
                  }`}
                </div>
              </div>
            </div>
            {Subtask && Subtask.length !== 0 && (
              <div className="flex ml-auto mr-0">
                {/* <p className="flex my-auto">{Subtask.length} Tâches</p> */}
                <div className="w-5 ml-2 flex my-auto">
                  <ChevronDownIcon
                    className={`my-auto stroke-[2.5] transform ${
                      deployed ? "rotate-180" : ""
                    } transition-transform duration-200`}
                  />
                </div>
              </div>
            )}
          </div>
          {Subtask && Subtask.length !== 0 && deployed && (
            <div
              className={`flex h-auto mb-3 transition-all duration-200 ease-out flex-row`}>
              <div className="w-[50px]">
                {/* Deployed line */}
                <div className="ml-[13px] h-full w-1 border-l-2 border-base-300"></div>
              </div>
              <div className="mt-8 space-y-8 flex flex-col grow">{renderSubtasks()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
