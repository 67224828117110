import {createContext, FC, useState} from "react";
import ThemeService from "../../services/theme.service";

interface ITitle {
  title: string;
  setTitle: (title: string) => void;
}

export const TitleContext = createContext<ITitle>({} as ITitle);

export const TitleContextProvider: FC = ({children}) => {
  const [title, setTitle] = useState("RajApp");

  const handleTitleChange = (title: string): void => {
    setTitle(title);
    document.title = "Rajapp - " + title;
  };

  return (
    <TitleContext.Provider value={{title: title, setTitle: handleTitleChange}}>
      {children}
    </TitleContext.Provider>
  );
};
