import axios from "axios";
import {FC, useState, useEffect, useReducer, useContext} from "react";
import {Link, useSearchParams} from "react-router-dom";
import ThemeDropdown from "../../components/ThemeDropdown";
import {cdn_url} from "../../config";
import {ArrowCircleRightIcon} from "@heroicons/react/outline";
import formReducerService from "../../services/formReducer.service";
import {TitleContext} from "../../components/Context/TitleContextProvider";

export enum TempCodeType {
  Reset = "confirmReset",
  Register = "confirmRegister",
}

interface IProps {
  code_type: TempCodeType;
}

export const TempCode: FC<IProps> = ({code_type}: IProps): JSX.Element => {
  const {setTitle} = useContext(TitleContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const token = String(searchParams.get("token"));

  const emailConfirm = async (token: string) => {
    setLoading(true);
    await axios
      .post(`/auth/confirmRegister`, {}, {params: {token: token}})
      .then((res: any) => {
        return res.data;
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error: any) => {
        console.log(error);
        setSuccess(false);
      });
    setLoading(false);
  };

  const passwordReset = async (token: string, password: string) => {
    setLoading(true);
    await axios
      .post(`/auth/confirmReset`, {}, {params: {reset_token: token, password: password}})
      .then((res: any) => {
        return res.data;
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error: any) => {
        console.log(error);
        setSuccess(false);
        code_type = TempCodeType.Register;
      });
    setLoading(false);
  };

  useEffect(() => {
    if (code_type === TempCodeType.Register) {
      emailConfirm(token);
      setTitle("Verification email");
    } else {
      setTitle("Nouveau mot de passe");
    }
  }, []);

  const formReducer = formReducerService.formReducer;

  const [formData, setFormData] = useReducer(formReducer, {
    password: "",
    password_confirm: "",
  });

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <div className="min-h-screen select-none bg-base-200">
      <div className="absolute h-16 p-2 flex w-full justify-end">
        <ThemeDropdown />
      </div>
      <div className="select-none max-w-3xl mx-auto w-full flex flex-col">
        {code_type === TempCodeType.Reset ? (
          <div className="card lg:card-side bg-base-100 rounded-xl mt-40 mx-5">
            <figure
              className={`min-h-[130px] min-w-[130px] lg:min-w-[180px] flex ${
                success === null ? "bg-warning" : !success ? "bg-error" : "bg-success"
              }`}>
              <img
                className={`select-none w-24`}
                src={`${cdn_url}public/icons/locker-dynamic-color.png`}
                alt="tick"
              />
            </figure>
            <div className="flex w-full">
              <div className="card rounded-xl card-compact px-7 py-3 w-full">
                <div className="card-body">
                  <div className="pt-5 pb-7">
                    {success === null ? (
                      <div className="max-w-md mx-auto">
                        <h1 className="text-6xl pt-5 font-extrabold">Et hop !</h1>
                        <div className="flex flex-col mx-auto space-y-4">
                          <p className="mt-7 text-base font-normal">
                            Créez votre nouveau mot de passe.
                          </p>
                          <div className="space-y-4">
                            <div className="form-control">
                              <label className="label flex-none gap-2">
                                <span className="label-text font-semibold">
                                  Mot de passe
                                </span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                placeholder="mot de passe"
                                onChange={handleChange}
                                className="input input-bordered !bg-base-200 !text-inherit
                                           placeholder-shown:!input placeholder-shown:!bg-base-200 placeholder-shown:!input-bordered placeholder-shown:!caret-base-content
                                           valid:!border-success
                                           invalid:!text-error invalid:border-error
                                           focus:ring-1
                                           focus:placeholder-shown: !outline-none
                                           focus:valid:ring-success
                                           focus:invalid:text-error focus:invalid:ring-error
                                           focus:invalid:placeholder-shown:!ring-0"
                                required
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$"
                                disabled={loading}
                              />
                            </div>
                            <div className="form-control">
                              <label className="label flex-none gap-2">
                                <span className="label-text font-semibold">
                                  Confirmation du mot de passe
                                </span>
                              </label>
                              <input
                                name="password_confirm"
                                type="password"
                                placeholder="mot de passe"
                                onChange={handleChange}
                                className="input input-bordered !bg-base-200 !text-inherit
                                           placeholder-shown:!input placeholder-shown:!bg-base-200 placeholder-shown:!input-bordered placeholder-shown:!caret-base-content
                                           valid:!border-success
                                           invalid:!text-error invalid:border-error
                                           focus:ring-1
                                           focus:placeholder-shown: !outline-none
                                           focus:valid:ring-success
                                           focus:invalid:text-error focus:invalid:ring-error
                                           focus:invalid:placeholder-shown:!ring-0"
                                required
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$"
                                disabled={loading}
                              />
                            </div>
                          </div>
                          <button
                            className="btn btn-primary group normal-case btn-block !mt-10"
                            disabled={
                              loading ||
                              formData.password !== formData.password_confirm ||
                              formData.password === "" ||
                              formData.password_confirm === 0
                            }
                            onClick={() => passwordReset(token, formData.password)}>
                            Changer mon mot de passe
                          </button>
                        </div>
                      </div>
                    ) : !success ? (
                      <div>
                        <h1 className="text-6xl pt-5 font-extrabold">Oups !</h1>
                        <p className="mt-7 text-base font-normal">
                          Malheureusement votre lien à expiré ou est invalide.
                        </p>
                        <div className="mt-10 card-actions justify-end">
                          <Link
                            className="btn btn-primary group normal-case btn-block"
                            to="/">
                            <span
                              className="flex-none w-6 h-6 mr-3
                                 group-hover:translate-x-1 group-hover:scale-100 ease-in-out duration-150">
                              <ArrowCircleRightIcon className="my-auto stroke-[1.7]" />
                            </span>
                            Retourner à l'accueil
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-6xl pt-5 font-extrabold">Bravo !</h1>
                        <p className="mt-7 text-base font-normal">
                          Votre nouveau mot de passe est mis à jour et vous pouvez dès à
                          présent l'utiliser pour vous connecter.
                        </p>
                        <div className="mt-10 card-actions justify-end">
                          <Link
                            className="btn btn-primary group normal-case btn-block"
                            to="/auth/login">
                            <span
                              className="flex-none w-6 h-6 mr-3
                                 group-hover:translate-x-1 group-hover:scale-100 ease-in-out duration-150">
                              <ArrowCircleRightIcon className="my-auto stroke-[1.7]" />
                            </span>
                            Se connecter
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card lg:card-side bg-base-100 rounded-xl mt-40 mx-5">
            <figure
              className={`min-h-[130px] min-w-[130px] lg:min-w-[180px] flex ${
                !success ? "bg-error" : "bg-success"
              }`}>
              <img
                className={`select-none w-24`}
                src={`${cdn_url}public/icons/thumb-${
                  !success ? "down" : "up"
                }-dynamic-color.png`}
                alt="tick"
              />
            </figure>
            <div className="flex w-full">
              <div className="card card-compact rounded-xl px-5 w-full">
                <div className="card-body">
                  <div className="py-5">
                    {!success ? (
                      <div>
                        <h1 className="text-6xl pt-5 font-extrabold">Oups !</h1>
                        <p className="mt-7 text-base font-normal">
                          Malheureusement votre lien à expiré ou est invalide.
                        </p>
                        <div className="mt-10 card-actions justify-end">
                          <Link
                            className="btn btn-primary group normal-case btn-block"
                            to="/">
                            <span
                              className="flex-none w-6 h-6 mr-3
                                 group-hover:translate-x-1 group-hover:scale-100 ease-in-out duration-150">
                              <ArrowCircleRightIcon className="my-auto stroke-[1.7]" />
                            </span>
                            Retourner à l'accueil
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-6xl pt-5 font-extrabold">Bravo !</h1>
                        <p className="mt-7 text-base font-normal">
                          Votre email est confirmé et vous pouvez dès à présent vous
                          connecter à RajApp.
                        </p>
                        <div className="mt-10 card-actions justify-end">
                          <Link
                            className="btn btn-primary group normal-case btn-block"
                            to="/auth/login">
                            <span
                              className="flex-none w-6 h-6 mr-3
                                 group-hover:translate-x-1 group-hover:scale-100 ease-in-out duration-150">
                              <ArrowCircleRightIcon className="my-auto stroke-[1.7]" />
                            </span>
                            Se connecter
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
