import {IDashboardItem} from "../interfaces";
import history from "../tools/Routes/CustomHistory";
import TimerFrom from "./TimerFrom";

export default function DashboardItem({list_id, name, image_url}: IDashboardItem) {
  return (
    <div
      className="list cursor-pointer"
      onClick={() => {
        history.push(`/list/${list_id}`);
      }}>
      <h3>{name}</h3>
    </div>
  );
}
