import {FC, useContext} from "react";
import List from "./List";
import {ListEdit} from "./ListEdit";
import {ListContext} from "../../components/Context/ListContextProvider";

export const ListContainer: FC = (): JSX.Element => {
  const {isEdit} = useContext(ListContext);

  return isEdit ? <ListEdit /> : <List />;
};
