import React, {FC, useState} from "react";
import Cropper from "react-easy-crop";

interface IProps {
  image: string;
  onCropDone: Function;
  onCropCancel: any;
  aspectRatio: number;
  cropperClassAtr?: string;
  buttonsClassAtr?: string;
}

export const ImageCropper: FC<IProps> = ({
  image,
  onCropDone,
  onCropCancel,
  aspectRatio,
  cropperClassAtr,
  buttonsClassAtr,
}: IProps) => {
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <div className="space-y-4">
      <div className={`overflow-hidden ${cropperClassAtr}`}>
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              position: "relative",
            },
          }}
        />
      </div>
      <div>
        <div className={`flex gap-4 place-content-end ${buttonsClassAtr}`}>
          <button
            className="btn btn-lg lg:btn-md btn-ghost normal-case"
            onClick={onCropCancel}>
            Annuler
          </button>
          <button
            className="btn grow lg:grow-0 btn-lg lg:btn-md btn-primary normal-case"
            onClick={() => {
              onCropDone(croppedArea);
            }}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};
