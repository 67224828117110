import {HomeIcon, LockClosedIcon, ViewGridIcon} from "@heroicons/react/outline";
import CardWithButton from "../../components/CardWithButton";
import {useContext, useEffect} from "react";
import {TitleContext} from "../../components/Context/TitleContextProvider";

interface IProps {
  message?: string;
}

export default function NoRights({message = undefined}: IProps) {
  const PAGE_TITLE = "Accès refusé";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  return (
    <div className="mx-auto max-w-fit my-auto">
      <CardWithButton
        titleText="Accès refusé"
        titleIcon={<LockClosedIcon className="my-auto stroke-[2]" />}
        messageText={message}
        buttonText="Dashboard"
        buttonIcon={<ViewGridIcon className="my-auto stroke-[1.7]" />}
        redirectLink="/dashboard"
        buttonText2="Accueil"
        buttonIcon2={<HomeIcon className="my-auto stroke-[1.7]" />}
        redirectLink2="/"
        multiButton
        alertType="warning"
      />
    </div>
  );
}
