import {useContext, useEffect, useState} from "react";
import DashboardItem from "../../components/DashboardItem";
import Loading from "../../components/Loading";
import {IDashboardItem} from "../../interfaces";
import {TitleContext} from "../../components/Context/TitleContextProvider";
const axios = require("axios").default;

interface IProps {
  dashboardItems?: IDashboardItem[];
}

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState<IDashboardItem[]>([]);

  const PAGE_TITLE = "Dashboard";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  const fetchLists = () => {
    setLoading(true);
    axios
      .get(`/list`)
      .then((res: any) => res.data)
      .then((results: any) => {
        setLists(results);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const renderDashboardItems = () => {
    return lists.slice().map((item, index) => {
      return (
        <DashboardItem
          list_id={item.list_id}
          name={item.name}
          key={index}></DashboardItem>
      );
    });
  };

  //TODO when loading show loading wheel
  return (
    <div className="select-none mx-auto w-full">
      {loading ? (
        <Loading text="Chargement de vos listes" />
      ) : (
        <div>
          <h1>Dashboard</h1>
          {lists.length !== 0 || !lists ? (
            <div id="lists">
              <h2>Mes listes</h2>
              {renderDashboardItems()}
            </div>
          ) : (
            <h2 className="no-data">Vous n'avez aucune liste</h2>
          )}
        </div>
      )}
    </div>
  );
}
