import {useLocation} from "react-router-dom";
import history from "../tools/Routes/CustomHistory";

interface IProps {
  title: string;
  linkTo: string;
  image_url?: any;
  Icon?: any;
}

export default function HeaderButton({title, linkTo, image_url, Icon}: IProps) {
  const windowLocation = useLocation();

  return (
    <div
      className="flex p-0 pr-4 hover:bg-transparent gap-0 group"
      onClick={() => {
        history.push(linkTo);
      }}>
      <span
        className={`bg-transparent rounded-r-full w-[5px] h-2
                      ${
                        windowLocation?.pathname == linkTo &&
                        "!bg-base-content !h-10 transition-all duration-200 ease-in-out"
                      }`}></span>
      <div
        className={`!rounded-none cursor-pointer flex gap-4 py-3 text-base-content text-opacity-70 font-medium ml-6
                    group-hover:text-opacity-100
                    group-active:text-base-content group-active:!text-opacity-100 group-active:!font-semibold group-active:bg-transparent
      ${
        windowLocation?.pathname == linkTo
          ? "text-base-content !text-opacity-100 !font-semibold border-base-content \
             hover:rounded-none"
          : ""
      }`}>
        {image_url ? (
          <img
            className={`outline-4 outline-base-200 rounded-full h-9 w-9`}
            src={image_url}
            title={title}></img>
        ) : Icon ? (
          <div className="flex-none w-9 h-9">{Icon}</div>
        ) : (
          <div
            className={`rounded-full outline-4 outline-base-200 h-9 w-9 flex-col font-medium bg-accent flex items-center justify-center text-base text-accent-content`}
            title={title}>
            {title.charAt(0)}
          </div>
        )}
        <span className="flex-1 text-xl font-medium my-auto">{title}</span>
      </div>
    </div>
  );
}
