import { BadgeCheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ILastDoneItem } from "../interfaces";
import { LastDoneItem } from "./LastDoneItem";
import Loading from "./Loading";
import WarningBlock from "./WarningBlock";

export const LastDone: FC = () => {
  let { LIST_ID } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [lastDoneItems, setLastDoneItems] =
    useState<Partial<ILastDoneItem[]>>();

  const fetchData = async () => {
    setLoading(true);
    await axios
      .get(`/list/lastdone`, {params: {list_id: LIST_ID}})
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        setError(false);
        setLastDoneItems(result);
      })
      .catch(function (error: any) {
        setError(true);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [LIST_ID]);

  const renderLastDoneItems = () => {
    if (!lastDoneItems) return;
    return lastDoneItems.slice().map((item, index) => {
      return (
        <LastDoneItem
          last_done_item_id={item?.last_done_item_id || -1}
          key={index}
          name={item?.name || ""}
          last_update={item?.last_update || ""}
        />
      );
    });
  };

  return (
    <div className="card card-compact p-2 bg-base-100">
      <div className="card-body space-y-3">
        <div className="card-title">
          <span className="flex-none w-5 h-5">
            <BadgeCheckIcon className="my-auto stroke-[2.5]" />
          </span>
          <h2 className="font-semibold text-2xl">Dernière fois</h2>
        </div>
        {loading ? (
          <div className="flex-1 space-y-3">
            <Loading text="Chargement des dernières fois" header />
          </div>
        ) : error || lastDoneItems?.length === 0 ? (
          <div className="flex-1 space-y-3">
            <WarningBlock text="Aucune dernière fois trouvée" />
          </div>
        ) : (
          <div className="gap-2 grid grid-cols-2">{renderLastDoneItems()}</div>
        )}
      </div>
    </div>
  );
};
