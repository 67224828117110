import {createContext, FC, useState} from "react";
import ThemeService from "../../services/theme.service";

interface ITheme {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ThemeContext = createContext<ITheme>({
  theme: ThemeService.getLocalTheme(),
  setTheme: ThemeService.setLocalTheme,
});

export const ThemeContextProvider: FC = ({children}) => {
  const [theme, setTheme] = useState(ThemeService.getLocalTheme());

  const handleThemeChange = (theme: string): void => {
    setTheme(theme);
    ThemeService.setLocalTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{theme: theme, setTheme: handleThemeChange}}>
      {children}
    </ThemeContext.Provider>
  );
};
