import {PencilIcon, PhotographIcon} from "@heroicons/react/outline";
import {FC, useState, useContext, useReducer, useEffect} from "react";
import {ListContext} from "./Context/ListContextProvider";
import formReducerService from "../services/formReducer.service";
import {ThemeContext} from "./Context/ThemeContextProvider";
import {ImageCropper} from "./ImageCropper";
import {FileInput} from "./FileInput";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import axios from "axios";
import {useParams} from "react-router-dom";

export const EditListDetails: FC = (): JSX.Element => {
  let {LIST_ID} = useParams();
  const {listDetails, fetchDetails} = useContext(ListContext);
  const {theme: theme} = useContext(ThemeContext);

  const [displayModal, setDisplayModal] = useState(false);
  const [changing, setChanging] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [cropCover, setCropCover] = useState(false);
  const [coverImage, setCoverImage] = useState(listDetails?.cover_image_url || null);
  const [cropProfile, setCropProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(listDetails?.image_url || null);

  const formReducer = formReducerService.formReducer;
  const [formData, setFormData] = useReducer(formReducer, {
    list_title: listDetails?.name || "",
  });
  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const cropImage = (
    imgCroppedArea: any,
    image: any,
    setImage: (image: any) => void,
    cropDims: [number, number]
  ) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = cropDims[0];
    canvasEle.height = cropDims[1];

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context?.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        // imgCroppedArea.width,
        // imgCroppedArea.height
        cropDims[0],
        cropDims[1]
      );

      const dataURL = canvasEle.toDataURL("image/jpeg", 1);

      setImage(dataURL);
    };
  };

  const updateListDetails = async () => {
    setChanging(true);

    var bodyFormData = new FormData();

    if (profileImage !== listDetails?.image_url && profileImage) {
      const profileImageBlob = await (await fetch(profileImage)).blob();
      bodyFormData.append(
        "image",
        profileImageBlob.slice(0, profileImageBlob.size, "image/jpeg"),
        "image.jpeg"
      );
    }

    if (coverImage !== listDetails?.cover_image_url && coverImage) {
      const coverImageBlob = await (await fetch(coverImage)).blob();
      bodyFormData.append(
        "cover_image",
        coverImageBlob.slice(0, coverImageBlob.size, "image/jpeg"),
        "cover_image.jpeg"
      );
    }

    await axios({
      method: "post",
      url: `/list/details`,
      data: bodyFormData,
      params: {list_id: LIST_ID, name: formData.list_title},
      headers: {"Content-Type": "multipart/form-data"},
    })
      .then((res: any) => {
        return res.data;
      })
      .then(async (result: any) => {
        try {
          setSubmitted(true);
          fetchDetails();
          setTimeout(() => {
            setSubmitted(false);
            setDisplayModal(false);
          }, 1200);
        } catch (err) {
          throw err;
        }
        return;
      })
      .catch(function (error: any) {});
    setChanging(false);
  };

  useEffect(() => {
    setFormData({name: "list_title", value: listDetails?.name});
    setCoverImage(listDetails?.cover_image_url || "");
    setProfileImage(listDetails?.image_url || "");
  }, [displayModal]);

  return (
    <div className="">
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={displayModal}
        readOnly
      />
      <div className="modal modal-bottom lg:modal-middle">
        <div className="modal-box flex flex-col space-y-4">
          <div className="">
            {!cropProfile &&
              (!cropCover ? (
                <label htmlFor="cover_upload">
                  {coverImage ? (
                    <div className="rounded-xl h-52 -mt-3 -mx-3 relative group hover:cursor-pointer">
                      <img
                        className="h-52 object-cover w-full rounded-xl overflow-hidden hover group-hover:grayscale-[80%]"
                        src={coverImage}
                        // onClick={}
                      />
                      <div className="absolute h-full w-full bottom-0 py-[4rem]">
                        <PhotographIcon className="text-primary-content rounded-full border-2 border-primary-content h-20 stroke-[1.2] mx-auto backdrop-blur p-2" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`h-52 rounded-xl overflow-hidden -mt-3 -mx-3 hover:cursor-pointer ${
                        theme === "clear"
                          ? "bg-topo-pattern-clear bg-secondary"
                          : "bg-topo-pattern-dark bg-base-100"
                      }`}>
                      <div className="h-full w-full py-[4rem]">
                        <PhotographIcon className="text-primary-content rounded-full border-2 border-primary-content h-20 stroke-[1.2] mx-auto backdrop-blur p-2" />
                      </div>
                    </div>
                  )}

                  <FileInput
                    onImageSelected={(image: any) => {
                      setCoverImage(image);
                      setCropCover(true);
                    }}
                    id="cover_upload"
                  />
                </label>
              ) : (
                <div className="">
                  <ImageCropper
                    image={coverImage || ""}
                    aspectRatio={3 / 1}
                    onCropDone={(croppedArea: any) => {
                      cropImage(croppedArea, coverImage, setCoverImage, [
                        1000,
                        1000 / (3 / 1),
                      ]);
                      setCropCover(false);
                    }}
                    onCropCancel={() => {
                      setCoverImage(listDetails?.cover_image_url || "");
                      setCropCover(false);
                    }}
                    cropperClassAtr="rounded-xl h-96 -mt-3 -mx-3"
                  />
                </div>
              ))}
            {!cropCover &&
              (!cropProfile ? (
                <div className="w-fit">
                  {profileImage ? (
                    <div className="h-24 w-24 relative group">
                      <label htmlFor="profile_upload" className="">
                        <img
                          className="
                           ml-5 -mt-12 rounded-full ring-base-100 bg-base-200 ring-4 relative group-hover:grayscale-[80%]"
                          src={profileImage}
                        />
                        <div className="rounded-full ml-5 absolute h-full w-full bottom-0 py-[1.5rem] hover:cursor-pointer">
                          <PhotographIcon className="text-primary-content rounded-full border-[2px] border-primary-content h-12 stroke-[1.2] mx-auto backdrop-blur p-1" />
                        </div>
                      </label>
                    </div>
                  ) : (
                    <label
                      htmlFor="profile_upload"
                      className={`ml-5 -mt-12 relative rounded-full h-24 w-24 ring-base-100 ring-4 flex-col font-medium bg-accent flex items-center justify-center text-6xl text-accent-content hover:cursor-pointer`}>
                      <div className="absolute">{listDetails?.name.charAt(0)}</div>
                    </label>
                  )}
                  <FileInput
                    onImageSelected={(image: any) => {
                      setProfileImage(image);
                      setCropProfile(true);
                    }}
                    id="profile_upload"
                  />
                </div>
              ) : (
                <div>
                  <ImageCropper
                    image={profileImage || ""}
                    aspectRatio={1 / 1}
                    onCropDone={(croppedArea: any) => {
                      cropImage(croppedArea, profileImage, setProfileImage, [500, 500]);
                      setCropProfile(false);
                    }}
                    onCropCancel={() => {
                      setProfileImage(listDetails?.image_url || "");
                      setCropProfile(false);
                    }}
                    cropperClassAtr="rounded-xl h-96 -mt-3 -mx-3"
                  />
                </div>
              ))}
          </div>
          <div className={`${(cropCover || cropProfile) && "hidden"}`}>
            <div className="form-control">
              <label className="label">
                <span className="label-text font-semibold">Nom de la liste</span>
              </label>
              <div className="flex flex-col">
                <input
                  placeholder="Nom"
                  name="list_title"
                  type="text"
                  value={formData.list_title}
                  className="font-bold text-3xl input-lg input flex w-auto !bg-base-200"
                  onChange={handleChange}></input>
              </div>
            </div>
            <div className="pt-8 flex place-content-end gap-4">
              <button
                className={`btn btn-lg lg:btn-md px-7 btn-ghost normal-case ${
                  (changing || submitted) && "hidden"
                }`}
                onClick={() => {
                  setDisplayModal && setDisplayModal(false);
                }}>
                Annuler
              </button>
              <button
                className={`btn grow lg:grow-0 btn-lg lg:btn-md btn-primary normal-case ease-in-out duration-700 transition-all animate-none !ml-0 ${
                  changing && "loading"
                } ${submitted && "btn-success !grow"}
                    active:hover:transform-none active:hover:animate-none
                    active:focus:transform-none active:focus:animate-none`}
                onClick={() => {
                  updateListDetails();
                }}>
                {submitted && (
                  <UseAnimations
                    animation={radioButton}
                    className={`stroke-neutral-content !h-[1.4rem] mr-1 ${
                      submitted && "stroke-success-content"
                    }`}
                    strokeColor="var(--n)"
                    speed={0.7}
                    size={16}
                    autoplay
                    wrapperStyle={{height: "auto", width: "auto"}}
                  />
                )}
                {submitted ? "Terminé" : changing ? "Changement en cours" : "Changer"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-circle border-none bg-base-100 text-base-content hover:text-primary-content normal-case gap-2"
        onClick={() => setDisplayModal(true)}>
        <PencilIcon className="h-5 w-5 stroke-[2]" />
      </button>
    </div>
  );
};
