import {useContext, useEffect} from "react";
import {TitleContext} from "../../components/Context/TitleContextProvider";

export default function Settings() {
  const PAGE_TITLE = "Parametres";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));
  return (
    <div>
      <h1>Settings</h1>
    </div>
  );
}
