import {useContext, useEffect} from "react";
import {TitleContext} from "../../components/Context/TitleContextProvider";

export default function NotFound() {
  const PAGE_TITLE = "Non trouvé";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  return (
    <div className="mx-auto my-4 text-gray-700">
      <h1 className="px-auto text-2xl font-bold">Erreur 404</h1>
      <p>Page non-existante</p>
    </div>
  );
}
