import {useOutletContext} from "react-router-dom";
import {TitleContext} from "../../components/Context/TitleContextProvider";
import {useState, useContext, useEffect, useReducer} from "react";
import formReducerService from "../../services/formReducer.service";
import axios from "axios";

export default function ForgotPassword() {
  const PAGE_TITLE = "Mot de passe oublié";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  const setDisplayModal: Function = useOutletContext();
  const [loading, setLoading] = useState<boolean>(false);
  const formReducer = formReducerService.formReducer;

  const [formData, setFormData] = useReducer(formReducer, {
    email: "",
  });

  const initReset = async (email: string) => {
    setLoading(true);
    await axios
      .post(`/auth/reset`, {}, {params: {email: email}})
      .then((res: any) => {
        return res.data;
      })
      .then(() => {
        setDisplayModal(true);
      })
      .catch((error: any) => {
        console.log(error);
      });
    setLoading(false);
  };

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <div className="card w-96">
      <div className="card-body">
        <form className="space-y-4">
          <h1 className="font-medium text-4xl">Mot de passe oublié</h1>
          <p>Demandez à réinitiliser votre mot de passe en recevant un mail.</p>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-semibold">Votre email</span>
            </label>
            <input
              name="email"
              type="email"
              placeholder="email"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit focus:outline-none
                         placeholder-shown:!border-none placeholder-shown:!bg-base-200 placeholder-shown:!caret-base-content
                         invalid:border-error
                         focus:ring-1
                         focus:placeholder-shown:!outline-none
                         focus:valid:ring-success
                         focus:invalid:ring-error
                         focus:invalid:placeholder-shown:!ring-0"
              required
              disabled={loading}
            />
          </div>
          <div className="card-actions pt-6">
            <button
              className="btn btn-primary group normal-case btn-block"
              disabled={loading || !formData.email}
              onClick={() => initReset(formData.email)}>
              Demander à changer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
