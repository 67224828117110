import {url} from "./config";
import {Navigate, Route, Routes} from "react-router-dom";
import ProtectedRoute from "./tools/Routes/ProtectedRoute";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import NotFound from "./pages/ErrorPages/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import List from "./pages/List/List";
import {Helmet} from "react-helmet";
import TokenService from "./services/token.service";
import Header from "./components/Header";
import Loading from "./components/Loading";
import {useContext, useState} from "react";
import {BrowserRouter} from "./tools/Routes/CutomRouter";
import history from "./tools/Routes/CustomHistory";
import Navbar from "./components/Navbar";
import LoginCard from "./components/LoginCard";
import SignUpCard from "./components/SignUpCard";
import {ListContextProvider} from "./components/Context/ListContextProvider";
import {ThemeContext} from "./components/Context/ThemeContextProvider";
import {ListContainer} from "./pages/List/ListContainer";
import {TempCode, TempCodeType} from "./pages/TempCode/TempCode";
const axios = require("axios").default;

export default function App() {
  const [headerOut, setHeaderOut] = useState(false);
  const {theme: theme} = useContext(ThemeContext);

  const handleHeaderClick = (): void => {
    setHeaderOut(false);
  };

  const handleNavClick = (): void => {
    setHeaderOut(!headerOut);
  };

  axios.defaults.baseURL = url;
  axios.interceptors.request.use(
    async (config: any) => {
      config.headers = {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    async (err: any) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token has expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axios.post("/auth/refresh", {
              refresh_token: TokenService.getLocalRefreshToken(),
            });

            const {bearerToken} = rs.data;

            TokenService.updateLocalAccessToken(bearerToken);
            return axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 402) {
          try {
            history.push("/auth/login");
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );

  return (
    <div
      data-theme={theme}
      className=" selection:bg-primary selection:text-primary-content">
      <BrowserRouter history={history}>
        <div>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
              rel="stylesheet"></link>
          </Helmet>
          <Routes>
            <Route
              path="/"
              element={
                !TokenService.getLocalAccessToken() ? (
                  <Home />
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              }></Route>
            <Route path="/auth" element={<Login />}>
              <Route path="login" element={<LoginCard />}></Route>
              <Route path="signup" element={<SignUpCard />}></Route>
              <Route path="confirmation" element={<SignUpCard />}></Route>
              <Route path="forgot" element={<ForgotPassword />}></Route>
            </Route>
            <Route
              element={
                <div className="w-screen">
                  <div className="bg-base-200 text-base-content drawer drawer-mobile">
                    <input
                      id="my-drawer"
                      type="checkbox"
                      className="drawer-toggle"
                      checked={headerOut}
                      readOnly={true}
                    />
                    <div className="drawer-content">
                      <Navbar onClick={handleNavClick} />
                      <div className="px-6 min-h-[calc(100vh-4rem)] flex">
                        <ProtectedRoute />
                      </div>
                    </div>
                    <div className="drawer-side">
                      <label
                        htmlFor="drawer"
                        className="drawer-overlay"
                        onClick={() => {
                          setHeaderOut(!headerOut);
                        }}></label>
                      <Header onClick={handleHeaderClick} />
                    </div>
                  </div>
                </div>
              }>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              <Route
                path="/list/:LIST_ID"
                element={
                  <ListContextProvider>
                    <ListContainer />
                  </ListContextProvider>
                }></Route>
            </Route>
            <Route path="*" element={<NotFound />}></Route>
            <Route path="404" element={<NotFound />}></Route>
            <Route
              path="/confirmReset"
              element={<TempCode code_type={TempCodeType.Reset} />}></Route>
            <Route
              path="/confirmRegister"
              element={<TempCode code_type={TempCodeType.Register} />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
