import {MoonIcon, SunIcon} from "@heroicons/react/outline";
import {useContext} from "react";
import {ThemeContext} from "../components/Context/ThemeContextProvider";

export default function ThemeDropdown() {
  const {theme: theme, setTheme: setTheme} = useContext(ThemeContext);

  return (
    <div
      className="flex my-auto h-fit hover:cursor-pointer ml-auto"
      onClick={() => {
        theme == "clear" ? setTheme("dark") : setTheme("clear");
      }}>
      <div className="p-2">
        {theme == "clear" ? (
          <MoonIcon className="h-7 w-7 stroke-[2]" />
        ) : (
          <SunIcon className="h-7 w-7 stroke-[2]" />
        )}
      </div>
    </div>
  );
}
