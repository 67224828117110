const formReducer = (state: any, event: any): any => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const formReducerService = {
  formReducer
}

export default formReducerService