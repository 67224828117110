import {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import ModalWithButton from "../../components/ModalWithButton";
import ThemeDropdown from "../../components/ThemeDropdown";
import {MailIcon, ArrowCircleRightIcon} from "@heroicons/react/outline";

export default function Login() {
  const windowLocation = useLocation();
  const navigate = useNavigate();
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  useEffect(() => {
    if (windowLocation.pathname == "/auth") {
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <div className="w-screen h-screen bg-base-200 flex">
      <div className="bg-base-100 min-h-screen flex flex-1 items-center place-content-center ">
        <div className="flex flex-col">
          <Outlet context={setDisplayModal} />
        </div>
      </div>
      <div className="absolute h-16 p-2 flex w-full justify-end">
        <ThemeDropdown />
      </div>
      <div className="bg-base-300 flex-1 hidden md:inline"></div>
      {displayModal && (
        <ModalWithButton
          titleText="Mail envoyé"
          messageText={`Encore un dernier petit effort !
                        Suivez simplement le lien que vous avez recu dans votre boîte mail.`}
          buttonText="Page de connexion"
          redirectLink="/auth/login"
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          titleIcon={<MailIcon className="my-auto stroke-[1.7]" />}
          buttonIcon={
            <ArrowCircleRightIcon className="my-auto stroke-[1.7]" />
          }></ModalWithButton>
      )}
    </div>
  );
}
