import {FC, useContext, useState} from "react";
import {ListContext} from "./Context/ListContextProvider";
import {ISubtask} from "../interfaces";

export const SubtaskFC: FC<ISubtask> = ({
  subtask_id,
  name,
  User,
  place,
  state,
}: ISubtask): JSX.Element => {
  const {loadingButtons, updateSubtask} = useContext(ListContext);
  const [changing, setChanging] = useState(false);

  const handleChange = async (new_state: boolean) => {
    setChanging(true);
    await updateSubtask(subtask_id, new_state);
    setChanging(false);
  };

  return (
    <div
      className="flex flex-row hover:cursor-pointer"
      onClick={() => handleChange(!state)}>
      <div className="flex mr-4">
        <input
          id={`input-${subtask_id.toString()}`}
          className={`checkbox my-auto h-7 w-7 rounded-[4px] checkbox-primary border-2 border-base-300 ${
            state && "opacity-100"
          }`}
          type="checkbox"
          checked={state}
          readOnly={true}
        />
      </div>
      {/* <div className="relative"> */}
      {/* <span
          className={`absolute w-0 h-[1.4px] -left-[0.1rem] bg-base-content top-[45%] transition-all ease-in-out duration-300 ${
            state ? "!w-[calc(100%+(2*0.1rem))]" : ""
          }`}></span> */}
      <div className={`flex my-auto text-base font-normal`}>{name}</div>
      {/* </div> */}
      {/* <div className="flex"></div> */}
    </div>
  );
};
