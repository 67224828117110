import history from "../tools/Routes/CustomHistory";

interface IProps {
  titleText: string;
  titleIcon?: any;
  messageText?: string;
  buttonText?: string;
  buttonIcon?: any;
  redirectLink?: string;
  buttonText2?: string;
  buttonIcon2?: any;
  redirectLink2?: string;
  alertType?: "info" | "success" | "warning" | "error";
  multiButton?: boolean;
}

export default function CardWithButton({
  titleText,
  titleIcon,
  messageText,
  buttonText,
  buttonIcon,
  redirectLink,
  buttonText2,
  buttonIcon2,
  redirectLink2,
  multiButton = false,
  alertType,
}: IProps) {
  return (
    <div
      className={`card-compact max-w-md cards alert ${
        alertType ? `alert-${alertType}` : "bg-base-100"
      }`}
    >
      {/* Needed to import all types to allow dynamic ${alertType} after because tailwind wouldn't import it otherwise */}
      <div className="hidden border-info-content border-success-content border-warning-content border-error-content text-info-content text-success-content text-warning-content text-error-content hover:bg-info-content hover:bg-success-content hover:bg-warning-content hover:bg-error-content hover:border-info-content hover:border-success-content hover:border-warning-content hover:border-error-content hover:text-info hover:text-success hover:text-warning hover:text-error alert-info alert-success alert-warning alert-error"></div>
      <div className="card-body space-y-3">
        <div className="justify-start">
          <h1 className="text-2xl font-bold flex">
            {titleIcon && (
              <span className="flex-none w-8 h-8 mr-3">{titleIcon}</span>
            )}
            {titleText}
          </h1>
        </div>
        {messageText && <p>{messageText}</p>}
        <div className="card-actions w-full btn-group gap-0">
          {buttonText && (
            <label
              className={`btn btn-outline px-0 ${
                multiButton && buttonText2 ? "flex-1 border-r-0" : "btn-block"
              } normal-case
                        ${
                          alertType &&
                          `border-${alertType}-content text-${alertType}-content
                        hover:bg-${alertType}-content hover:border-${alertType}-content hover:text-${alertType}`
                        }`}
              onClick={() => {
                redirectLink && history.push(redirectLink);
              }}
            >
              {buttonIcon && (
                <span className="flex-none w-6 h-6 mr-3">{buttonIcon}</span>
              )}
              {buttonText}
            </label>
          )}
          {multiButton && buttonText2 && (
            <label
              className={`btn btn-outline px-0 ${
                multiButton && buttonText2 ? "flex-1" : "btn-block"
              } normal-case
                        ${
                          alertType &&
                          `border-${alertType}-content text-${alertType}-content
                        hover:bg-${alertType}-content hover:border-${alertType}-content hover:text-${alertType}`
                        }`}
              onClick={() => {
                redirectLink2 && history.push(redirectLink2);
              }}
            >
              {buttonIcon2 && (
                <span className="flex-none w-6 h-6 mr-3">{buttonIcon2}</span>
              )}
              {buttonText2}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
