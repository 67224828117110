import {FC} from "react";

interface IProps {
  onImageSelected: Function;
  id: string;
}

export const FileInput: FC<IProps> = ({onImageSelected, id}: IProps) => {
  const handleOnChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  return (
    <input
      type="file"
      accept="image/*"
      id={id}
      onChange={handleOnChange}
      className="hidden"
    />
  );
};
