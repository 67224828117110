import history from "../tools/Routes/CustomHistory";

interface IProps {
  titleText: string;
  messageText: string;
  buttonText: string;
  displayModal?: boolean;
  setDisplayModal?: Function;
  redirectLink?: string;
  titleIcon?: any;
  buttonIcon?: any;
}

export default function ModalWithButton({
  titleText,
  messageText,
  buttonText,
  redirectLink,
  displayModal,
  setDisplayModal,
  titleIcon,
  buttonIcon,
}: IProps) {
  return (
    <div className="absolute w-screen h-screen select-none">
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={displayModal || true}
        readOnly={true}
      />
      <div className="modal modal-bottom lg:modal-middle">
        <div className="modal-box flex flex-col">
          <div className="flex">
            {titleIcon && <span className="flex-none w-8 h-8 mr-3">{titleIcon}</span>}
            <h3 className="flex-1 font-bold text-lg my-auto">{titleText}</h3>
          </div>
          <p className="py-4 whitespace-pre-line">{messageText}</p>
          <div className="modal-action group">
            <label
              htmlFor="my-modal"
              className="btn btn-primary btn-block normal-case"
              onClick={() => {
                redirectLink && history.push(redirectLink);
                setDisplayModal && setDisplayModal(false);
              }}>
              {buttonIcon && (
                <span
                  className="flex-none w-6 h-6 mr-3
                                 group-hover:translate-x-1 group-hover:scale-100 ease-in-out duration-150">
                  {buttonIcon}
                </span>
              )}
              {buttonText}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
