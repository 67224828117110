import {useContext, useEffect, useReducer, useState} from "react";
import ErrorBlock from "../components/ErrorBlock";
import TokenService from "../services/token.service";
import history from "../tools/Routes/CustomHistory";
import formReducerService from "../services/formReducer.service";
import axios from "axios";
import {Link} from "react-router-dom";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import {TitleContext} from "./Context/TitleContextProvider";

export default function LoginCard() {
  const formReducer = formReducerService.formReducer;

  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useReducer(formReducer, {
    email: "",
    password: "",
  });

  const PAGE_TITLE = "Connexion";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  const loginUser = async (credentials: any) => {
    setFormError(false);
    setLoading(true);
    axios
      .post(`/auth/login`, credentials.formData)
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        try {
          setLoading(false);
          setFormError(false);
          setSubmitted(true);
          TokenService.updateLocalAccessToken(result.bearerToken);
          TokenService.updateRefreshAccessToken(result.refreshToken);
          setTimeout(() => {
            setSubmitted(false);
            //TODO implement link continuation
            history.push("/dashboard");
          }, 1500);
        } catch (err) {
          throw err;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          setFormError(true);
          Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
          );
          setLoading(false);
          return;
        }
        console.log("Request failed", error);
        history.push("/404");
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setFormError(true);
    } else {
      loginUser({formData});
    }
  };

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <div className="card w-96">
      <div className="card-body">
        <form onSubmit={handleSubmit} className="space-y-4">
          <h1 className="font-medium text-4xl">Connexion</h1>
          <p>Veuillez vous connecter pour accéder à votre espace personnel</p>
          {formError && <ErrorBlock text={"Données invalides"}></ErrorBlock>}
          <div className="form-control">
            <label className="label">
              <span className="label-text font-semibold">Email</span>
            </label>
            <input
              name="email"
              type="email"
              placeholder="email"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit focus:outline-none
                         placeholder-shown:!border-none placeholder-shown:!bg-base-200 placeholder-shown:!caret-base-content
                         invalid:border-error
                         focus:ring-1
                         focus:placeholder-shown:!outline-none
                         focus:valid:ring-success
                         focus:invalid:ring-error
                         focus:invalid:placeholder-shown:!ring-0"
              required
              disabled={loading}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-semibold">Mot de passe</span>
            </label>
            <input
              name="password"
              type="password"
              placeholder="mot de passe"
              onChange={handleChange}
              className="input !bg-base-200 !text-inherit"
              required
              disabled={loading}
            />
            <label className="label">
              <a
                className="label-text-alt link link-hover"
                onClick={() => history.push("/auth/forgot")}>
                Mot de passe oublié ?
              </a>
            </label>
          </div>
          <div className="card-actions pt-6">
            <button
              className={`btn btn-primary btn-block normal-case ${loading && "loading"} ${
                submitted && "btn-success"
              }`}
              type="submit">
              {submitted && (
                <UseAnimations
                  animation={radioButton}
                  className={`stroke-neutral-content !h-[1.4rem] mr-2 ${
                    submitted && "stroke-success-content"
                  }`}
                  strokeColor="var(--n)"
                  speed={0.7}
                  size={16}
                  autoplay
                  wrapperStyle={{height: "auto", width: "auto"}}
                />
              )}
              {submitted ? "Connecté" : "Connexion"}
            </button>
          </div>
        </form>
      </div>
      <div className="label-text-alt font-semibold-alt text-sm mx-auto">
        <span>Pas encore de compte ? </span>
        <span className=" link md:link-hover">
          <Link to="/auth/signup">Inscrivez vous</Link>
        </span>
      </div>
    </div>
  );
}
