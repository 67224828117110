interface IProps {
  text: string;
  header?: boolean;
}

export default function Loading({ text, header }: IProps) {
  return (
    <div className="flex h-full w-full py-3 first:pt-5">
      <div className="content-center mx-auto my-auto max-w-fit">
        <div className="select-none flex items-center place-content-center">
          <button className="btn btn-square loading bg-transparent border-0 text-primary" />
          <h2
            className={
              header ? "flex flex-col my-auto px-4 text-sm" : "text-2xl"
            }
          >
            {text}
          </h2>
        </div>
      </div>
    </div>
  );
}
