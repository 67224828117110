import {useContext, useEffect, useState} from "react";
import {MenuIcon} from "@heroicons/react/outline";
import ThemeDropdown from "./ThemeDropdown";
import {TitleContext} from "./Context/TitleContextProvider";

interface IProps {
  onClick: Function;
}

export default function Navbar({onClick}: IProps) {
  const {title} = useContext(TitleContext);

  return (
    <div className="select-none sticky top-0 z-30 flex h-16 w-full justify-center transition-all duration-100 bg-base-200 shadow-sm border-b-neutral text-base-content">
      <nav className="navbar absolute">
        <div
          onClick={() => {
            onClick();
          }}>
          <div className="flex-none">
            <MenuIcon className="h-7 w-7 my-auto stroke-[2] ml-3 hover:cursor-pointer lg:hidden" />
          </div>
          <div className="flex-1">
            <a className="ml-4 text-2xl font-bold mr-3">{title}</a>
          </div>
        </div>
        <ThemeDropdown />
      </nav>
    </div>
  );
}
