import {FC} from "react";

interface IProps {
  image_url?: string | null;
  name?: string;
  custom_text?: string;
  custom_className?: string;
}

export const ImageCircle: FC<IProps> = ({
  image_url,
  name = "",
  custom_text,
  custom_className = "border-base-100",
}): JSX.Element => {
  return (
    <div
      className="tooltip tooltip-bottom tooltip-accent hover:before:whitespace-pre-line hover:before:text-left before:transition-none after:transition-none"
      data-tip={name}>
      {image_url ? (
        <img
          className={`border-4 rounded-full h-12 w-12 ${custom_className}`}
          src={image_url}
          alt={name}></img>
      ) : (
        <div
          className={`rounded-full border-4 h-12 w-12 flex-col font-medium bg-accent flex items-center justify-center text-base text-accent-content ${custom_className}`}>
          {custom_text ? custom_text : name.charAt(0)}
        </div>
      )}
    </div>
  );
};
