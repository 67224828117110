import {useContext, useEffect} from "react";
import {TitleContext} from "../../components/Context/TitleContextProvider";

export default function Profile() {
  const PAGE_TITLE = "Mon profil";
  const {setTitle} = useContext(TitleContext);
  useEffect(() => setTitle(PAGE_TITLE));

  return (
    <div>
      <h1>Profile</h1>
    </div>
  );
}
