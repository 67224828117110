const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return user?.bearerToken;
};

const updateLocalAccessToken = (token: any) => {
  let user = JSON.parse(localStorage.getItem("user") || "{}");
  user.bearerToken = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const updateRefreshAccessToken = (refreshToken: any) => {
  let user = JSON.parse(localStorage.getItem("user") || "{}");
  user.refreshToken = refreshToken;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user") || "{}");
};

const setUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateRefreshAccessToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
