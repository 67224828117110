import {FC, useState} from "react";
import {CalendarIcon, BadgeCheckIcon, ChevronRightIcon} from "@heroicons/react/outline";
import TimerFrom from "./TimerFrom";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import axios from "axios";

var moment = require("moment");

interface IProps {
  name: string;
  last_update: string;
  last_done_item_id: number;
}

export const LastDoneItem: FC<IProps> = ({
  name,
  last_update,
  last_done_item_id,
}): JSX.Element => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<string>(last_update);
  const [modalDateValue, setModalDateValue] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [changing, setChanging] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const updateLastDoneItem = async () => {
    setChanging(true);
    await axios
      .put(
        `/list/lastdone`,
        {},
        {
          params: {lastdone_item_id: last_done_item_id, new_date: modalDateValue},
        }
      )
      .then((res: any) => {
        return res.data;
      })
      .then(async (result: any) => {
        try {
          setSubmitted(true);
          setTimeout(() => {
            setLastUpdate(result?.last_update);
            setSubmitted(false);
            setDisplayModal(false);
            setModalDateValue(moment(new Date()).format("YYYY-MM-DD"));
          }, 1200);
        } catch (err) {
          throw err;
        }
        return;
      })
      .catch(function (error: any) {});
    setChanging(false);
  };

  return (
    <div>
      {displayModal && (
        <div className="absolute w-screen h-screen select-none">
          <input
            type="checkbox"
            id="my-modal"
            className="modal-toggle"
            checked={displayModal}
            readOnly={true}
          />
          <div className="modal modal-bottom lg:modal-middle">
            <div className="modal-box flex flex-col">
              <div className="flex">
                <span className="flex-none w-10 h-10 mr-3 my-auto">
                  <BadgeCheckIcon className="my-auto stroke-[1.7]" />
                </span>
                <p className="whitespace-pre-line flex-1 font-semibold text-xl my-auto">
                  <span>Confirmer le changement de </span>
                  <span className="before:block before:absolute before:-inset-y-[0.1rem] before:-inset-x-0 before:bg-base-200 before:rounded-md relative inline-block">
                    <span className="relative text-base-content px-2 py-0">{name}</span>
                  </span>
                </p>
              </div>
              <div className="space-y-3 pt-6">
                <div className="p-3 pt-5 border border-base-content border-opacity-25 bg-base-200 bg-opacity-[0.5] rounded-xl space-y-4 divide-y divide-base-content divide-opacity-30">
                  <div className="space-y-3">
                    <span className="flex font-normal my-auto text-center justify-center text-xl pb-3 pt-2">
                      Changement de la date
                    </span>
                    <div className="pb-4 px-[2px] whitespace-pre-line justify-center flex">
                      <span className="flex before:block before:absolute before:-inset-y-[.25rem] before:-inset-x-0 before:bg-base-200 before:bg-opacity-50 before:border before:border-error before:rounded-md relative">
                        <span className="relative text-base-content px-3 tracking-widest font-normal my-auto align-middle">
                          {moment(new Date(lastUpdate)).format("DD/MM/YYYY")}
                        </span>
                      </span>
                      <div className="flex w-15 h-8 mx-1 my-auto justify-center overflow-hidden">
                        <div className="grid w-8 h-8 p-1">
                          <ChevronRightIcon
                            className="my-auto stroke-[1.7] animate-belt-lr relative"
                            style={{
                              gridRow: 1,
                              gridColumn: 1,
                              animationFillMode: "both",
                            }}
                          />
                          <ChevronRightIcon
                            className="my-auto stroke-[1.7] animate-belt-lr relative"
                            style={{
                              gridRow: 1,
                              gridColumn: 1,
                              animationDelay: "333ms",
                              animationFillMode: "both",
                            }}
                          />
                          <ChevronRightIcon
                            className="my-auto stroke-[1.7] animate-belt-lr relative"
                            style={{
                              gridRow: 1,
                              gridColumn: 1,
                              animationDelay: "666ms",
                              animationFillMode: "both",
                            }}
                          />
                        </div>
                      </div>
                      <span className="flex before:block before:absolute before:-inset-y-[.25rem] before:-inset-x-0 before:bg-base-200 before:bg-opacity-50 before:border before:border-success before:rounded-md relative">
                        <span className="relative text-base-content px-3 tracking-widest font-normal my-auto">
                          {moment(modalDateValue).format("DD/MM/YYYY")}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pt-4">
                    <label className="label pt-0">Changer manuellement</label>
                    <div
                      className="flex bg-base-200 bg-opacity-75 rounded-lg border-2 border-base-content border-opacity-30
                               focus-within:border-primary">
                      <span className="flex-none w-5 h-5 ml-3 my-auto">
                        <CalendarIcon className="my-auto stroke-[2.5]" />
                      </span>
                      <div className="border-none box-border outline-0 relative w-full">
                        <input
                          type="date"
                          className="p-3 bg-transparent text-base-content outline-none w-full
                               active:border-primary"
                          value={modalDateValue}
                          onChange={(e) => {
                            setModalDateValue(e.currentTarget.value);
                          }}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-action group gap-2 pt-3">
                <button
                  className={`btn btn-outline px-7 normal-case bg-base-200 bg-opacity-40 border-base-content border-opacity-30 ${
                    (changing || submitted) && "hidden"
                  }`}
                  onClick={() => {
                    setDisplayModal && setDisplayModal(false);
                  }}>
                  Annuler
                </button>
                <button
                  className={`btn btn-primary grow-0 flex-initial w-56 lg:w-[22rem] normal-case ease-in-out duration-700 transition-all animate-none !ml-0 ${
                    changing && "loading !grow"
                  } ${submitted && "btn-success !grow"}
                    active:hover:transform-none active:hover:animate-none
                    active:focus:transform-none active:focus:animate-none`}
                  onClick={() => {
                    updateLastDoneItem();
                  }}>
                  {submitted && (
                    <UseAnimations
                      animation={radioButton}
                      className={`stroke-neutral-content !h-[1.4rem] mr-1 ${
                        submitted && "stroke-success-content"
                      }`}
                      strokeColor="var(--n)"
                      speed={0.7}
                      size={16}
                      autoplay
                      wrapperStyle={{height: "auto", width: "auto"}}
                    />
                  )}
                  {submitted
                    ? "Nouvelle date à jour"
                    : changing
                    ? "Changement en cours"
                    : "Confirmer la date"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`btn w- h-fit flex p-3 rounded-lg bg-opacity-40 bg-base-200 text-base-content border-2 border-base-content border-opacity-20 normal-case min-h-16
                   hover:border-opacity-40 hover:border-base-content hover:bg-base-200 hover:bg-opacity-100 hover:text-base-content
                   active:border-opacity-75 active:bg-base-200 active:bg-opacity-75 active:text-base-200-content
                   ${submitted && "border-success border-opacity-100"}`}
        onClick={() => {
          setDisplayModal(true);
        }}>
        <div className={`space-y-2 p-1`}>
          <h2 className={`text-lg`}>{name}</h2>
          <TimerFrom
            time={lastUpdate || "0001-01-01T00:00:00+00:00"}
            short
            dayOnly></TimerFrom>
        </div>
      </div>
    </div>
  );
};
