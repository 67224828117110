import {AnnotationIcon, InformationCircleIcon, TrashIcon} from "@heroicons/react/outline";
import axios from "axios";
import {FC, useEffect, useState} from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import {useParams} from "react-router-dom";
import {IComment} from "../interfaces";
import Loading from "./Loading";
import TimerFrom from "./TimerFrom";
import WarningBlock from "./WarningBlock";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";

export const Comment: FC = () => {
  let {LIST_ID} = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [changing, setChanging] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [comment, setComment] = useState<Partial<IComment>>();
  const [oldComment, setOldComment] = useState<string>(comment?.value || "");

  const fetchComment = async () => {
    setLoading(true);
    await axios
      .get(`/list/comment`, {params: {list_id: LIST_ID}})
      .then((res: any) => {
        return res.data;
      })
      .then((result: any) => {
        setError(false);
        setComment(result);
        setOldComment(result?.value);
      })
      .catch(function (error: any) {
        setError(true);
      });
    setLoading(false);
  };

  const updateComment = async () => {
    setChanging(true);
    await axios
      .put(`/list/comment`, {}, {
        params: {comment_id: comment?.comment_id, new_value: comment?.value},
      })
      .then((res: any) => {
        return res.data;
      })
      .then(async (result: any) => {
        try {
          setSubmitted(true);
          setTimeout(() => {
            setError(false);
            setComment(result);
            setOldComment(result?.value);
            setSubmitted(false);
          }, 2000);
        } catch (err) {
          throw err;
        }
        return;
      })
      .catch(function (error: any) {
        setError(true);
      });
    setChanging(false);
  };

  useEffect(() => {
    fetchComment();
  }, [LIST_ID]);

  const handleChange = (new_value: string) => {
    setComment({...comment, value: new_value});
  };

  const resetComment = () => {
    setTimeout(() => {
      setComment({...comment, value: oldComment});
    }, 200);
  };

  const validationAnimation = () => {};

  return (
    <div className="card card-compact p-2 bg-base-100">
      <div className="card-body space-y-3">
        <div className="card-title">
          <span className="flex-none w-5 h-5">
            <AnnotationIcon className="my-auto stroke-[2]" />
          </span>
          <h2 className="font-semibold text-2xl grow">Commentaire</h2>
          <div className="hidden lg:flex dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-info btn-outline border-0 btn-circle btn-xs content-center">
              <InformationCircleIcon className="my-auto stroke-[1.7] w-full" />
            </label>
            <div
              tabIndex={0}
              className="card compact dropdown-content rounded-lg my-auto shadow-sm bg-base-100 border-2 border-base-300 mt-7">
              <div className="card-body !p-3">
                <div className="font-normal flex gap-1 w-max">
                  <kbd className="kbd kbd-sm flex-none">ctrl|⌘</kbd>
                  <span className="my-auto flex-none">+</span>
                  <kbd className="kbd kbd-sm flex-none">Enter</kbd>
                  <span className="my-auto flex-none">pour valider.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex-1 space-y-3">
            <Loading text="Chargement du commentaire" header />
          </div>
        ) : error ? (
          <div className="flex-1 space-y-3">
            <WarningBlock text="Aucun commentaire trouvé" />
          </div>
        ) : (
          <div className="flex-1 space-y-3">
            <div
              className={`flex flex-col rounded-xl overflow-hidden border-2 border-base-content border-opacity-20 p-3 bg-base-200 bg-opacity-50
                          hover:border-primary-content hover:border-opacity-40  
                          focus-within:border-primary focus-within:border-opacity-100
                          hover:focus-within:border-primary hover:focus-within:border-opacity-100
                          transition-all duration-200 ${submitted && "!border-success"} ${
                changing && "!border-primary"
              }`}>
              <TextareaAutosize
                className={`textarea w-full rounded-none resize-none bg-transparent p-0 caret-primary-content
                            disabled:bg-transparent disabled:border-transparent
                            focus:outline-0
                            transition-all duration-200 ${
                              oldComment !== comment?.value && "mb-3"
                            }`}
                name="comment-area"
                id="comment-area"
                value={comment?.value}
                placeholder="Aucun commentaire"
                onChange={(e) => handleChange(e.currentTarget.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" &&
                    e.ctrlKey &&
                    oldComment !== comment?.value &&
                    updateComment();
                }}
                disabled={loading || changing}
              />
              <div
                className={`flex h-11 justify-between pt-3 transition-all duration-200 ease-out border-t border-t-base-content border-opacity-20 ${
                  oldComment === comment?.value && "border-opacity-0 !h-0 pt-0"
                }`}>
                <button
                  className={`btn-sm btn btn-square normal-case btn-error ${
                    oldComment === comment?.value && "hidden"
                  }`}
                  onClick={() => resetComment()}>
                  <div className="flex-none w-7 h-7 p-1 justify-center content-center flex">
                    <TrashIcon className="my-auto stroke-[1.7]" />
                  </div>
                </button>
                <button
                  className={`btn-sm btn btn-primary normal-case ease-in-out duration-500 transition-all flex w-24 before:!mr-0 ${
                    oldComment === comment?.value && "hidden"
                  } ${changing && "loading px-0 !w-8"} ${
                    submitted && "btn-success btn-square !w-8"
                  }`}
                  onClick={() => updateComment()}>
                  {submitted && (
                    <UseAnimations
                      animation={radioButton}
                      className={`stroke-neutral-content !h-[1.4rem] ${
                        submitted && "stroke-success-content"
                      }`}
                      strokeColor="var(--n)"
                      speed={0.7}
                      size={16}
                      autoplay
                      wrapperStyle={{height: "auto", width: "auto"}}
                    />
                  )}
                  {!submitted && !changing && "Valider"}
                </button>
              </div>
            </div>
            <div className="text-center">
              <TimerFrom
                time={comment?.last_update || "0001-01-01T00:00:00+00:00"}></TimerFrom>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
